import React from "react";

const Amenities = () => {
  return (
    <div className="pt-5">
      <div className="inclusions py-2">
        <p className="amenities-heading font-weight-bold">
          What is included on this safari?
        </p>
        <ul>
          <li>Activities as specified in the itinerary.</li>
          <li>Entrance fees as specified in the itinerary.</li>
          <li>Private 4WD Vehicle with experienced Naturalist throughout safari.</li>
          <li>Expert Safari Guide.</li>
          <li>Transfers as specified in the itinerary.</li>
        </ul>
      </div>
      <div className="exclusions py-2">
        <p className="amenities-heading font-weight-bold">
          What is not included on this safari?
        </p>
        <ul>
          <li>International Flights.</li>
          <li>Airport Taxes because your safari already has a transfer car arranged.</li>
          <li>Tips and Gratitudes to guides or any other person.</li>
          <li>Items of Personal Nature.</li>
        </ul>
      </div>
    </div>
  );
};

export default Amenities;
