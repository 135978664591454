export default [
  {
    title: "How can I book my Safari?",
    content: `Booking your safari with us is so simple. Fill out
    our reservations form and one of our experts will reach out to help
    you tailor your safari and direct you on how to make
    your deposit.`
  },
  {
    title: "Can I access Internet on my Safari?",
    content: `International telephone communication is very good from the cities but more difficult from some up-country areas. 
    The mobile telephone network is good and rapidly expanding. Our safari guides have mobile phones which can be used for incoming calls.
    Most European or American mobile phones with a roaming agreement work in East Africa. 
    Local SIM cards can be purchased cheaply (upon presentation of your passport). 
    Internet facilities are widely available in cities and in most major towns although the quality and speed of the connection varies.`
  },
  {
    title: "What is the weather like in Uganda?",
    content: `The Equatorial climate of East Africa is a major attraction with temperatures on the plains ranging between a very agreeable 24°C and 30°C. 
    The climate does not change much, meaning Uganda is an ideal holiday destination throughout the year.
    In the mountainous areas it is colder than on the plains (especially at night) and the rainfall is greater. 
    Temperatures in the mountainous areas can go down as low as 10°C in certain months.`
  },
  {
    title: "How is the general security in Uganda?",
    content: `Uganda is generally a secure country but do not forget to take the same basic safety and security precautions that you would in any other country.
    Make use of safety deposit boxes in the lodges to keep your travel documents and other valuables secure. 
    Do not carry excessive jewellery and money or leave them lying around. Do not walk unescorted at night. 
    Our expert guides are always available to escort you.`
  }
];
