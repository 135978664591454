import React from "react";

const TopNav = () => {
  return (
    <section className="topnav-section">
      <div className="container">
        <div className="row">
          <div className="col-sm-3">
            <a
              href="https://web.facebook.com/kisingasafarisug/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icons mr-lg-2 d-xl-0"
                src="icons/icon-facebook.png"
                alt="facebook icon"
                width={26}
              />
            </a>
            <a
              href="https://web.facebook.com/kisingasafarisug/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="social-icons d-xl-0"
                src="icons/icon-twitter.png"
                alt="twitter icon"
                width={26}
              />
            </a>
          </div>
          <div className="col-sm-9 text-right">
            <p className="call-number ml-lg-2 mr-lg-2 d-xl-0">
              Call Us: +256 783 700505
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopNav;
