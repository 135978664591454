import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ContactForm from "../components/contactForm";

const ContactUs = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <div className="hero-image-section">
        <div className="hero-image-contact-us">
          <div className="hero-text">
            <h1 className="hero-headng font-weight-bold">Contact Us</h1>
          </div>
        </div>
      </div>
      <div className="contact-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <div className="desc">
                Kisinga Safaris would love to welcome you to the kingdom of
                exploration! You can book your accommodation and safari
                arrangements directly by contacting us, or through your
                preferred travel agent or specialist tour operator.
                <br />
                <br />
                <strong>Physical Address</strong>
                <br />
                Plot 89, Mawanda Road
                <br />
                P.O Box 23434 Kampala - Uganda
                <br />
                <br />
                <strong>By Phone</strong>
                <br />
                Tel: +256 393 283211
                <br />
                Mob: +256 783 700505
                <br />
                <strong>
                  <br />
                  By Email
                  <br />
                </strong>
                info@kisingasafaris.com
                <br />
                <br />
                (Hours of operation: 9:00am-6:00pm EST)
              </div>
              <ContactForm />
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactUs;
