import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const Terms = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <div className="container py-5">
        <div>
          <div className="terms">
            <h3 className="terms-heading">Terms and Conditions</h3>
            <br />
            <div className="terms-block">
              <div>
                BOOKING TERMS AND CONDITIONS - UPDATED JULY 2019
                <br />
                <br />
                These Booking Conditions, together with our privacy policy
                and where your holiday is booked via our website, our
                website terms and conditions of use, together with any other
                written information we brought to your attention before we
                confirmed your booking, form the basis of your contract with
                Kisinga Safaris, (“we”, “us”, “our”). Please read them
                carefully as they set out our respective rights and
                obligations. In these Booking Conditions references to “you”
                and “your” include the first named person on the booking and
                all persons on whose behalf a booking is made or any other
                person to whom a booking is added or transferred. By making
                a booking, the first named person on the booking (the lead
                name) agrees on behalf of all persons detailed on the
                booking that:
                <br />
                <br />
                1. He/she has read these terms and conditions and has the
                authority to and does agree to be bound by them;
                <br />
                <br />
                2. He/she agrees to provide accurate and full information to
                the remainder of the travelling party in relation to the
                booking, including any changes thereto;
                <br />
                <br />
                3. He/she consents to our use of information in accordance
                with our Privacy Policy;
                <br />
                <br />
                4. He/she is over 18 years of age and where placing an order
                for services with age restrictions declares that he/she and
                all members of the party are of the appropriate age to
                purchase those services;
                <br />
                <br />
                5. He/she accepts financial responsibility for payment of
                the booking on behalf of all persons detailed on the
                booking.
                <br />
                <br />
                Our obligations to you will vary depending upon whether we
                act as a Package Organiser in the sale of a Package Holiday
                (i.e. a Safari) or as a Principal in the sale of
                single-element bookings (i.e. a lodge only bookings); our
                differing obligations are set out below, in three separate
                sections: Section A contains the conditions which will apply
                to all bookings. Section B contains the conditions which
                will apply when you make a booking with us where we act as
                the Package Organiser. Section C contains the conditions
                which will apply where you make a single-element booking
                with us, where we are acting as Principal.
                <br />
                <br />
                SECTION A – APPLICABLE TO ALL BOOKINGS
                <br />
                <br />
                1. Booking Your Arrangements
                <br />
                <br />
                A booking is made with us when a) you tell us that you would
                like to accept our written or verbal quotation; b) you pay
                us a deposit (or full payment if required at the time of
                booking, please see Sections B and C below); and c) we issue
                you with a booking confirmation. We reserve the right to
                return your deposit and decline to issue a confirmation at
                our absolute discretion.
                <br />
                <br />A binding contract will come into existence between
                you and us as soon as we have issued you with a booking
                confirmation that will confirm the details of your booking.
                Upon receipt, if you believe that any details on the
                confirmation or any other document are wrong you must advise
                us immediately as changes can not be made later and it may
                harm your rights if we are not notified of any inaccuracies
                in any document within ten days of our sending it out.
                <br />
                <br />
                2. Accuracy
                <br />
                <br />
                We endeavour to ensure that all the information and prices
                both on our website and in any advertising material that we
                issue are accurate; however occasionally changes and errors
                occur and we reserve the right to correct prices and other
                details in such circumstances. You must check the current
                price and all other details relating to the arrangements
                that you wish to book before your booking is confirmed.
                <br />
                <br />
                3. Insurance and Standards
                <br />
                <br />
                It is a condition of booking that you must have appropriate
                insurance for the type of holiday being taken with us. Such
                insurance should fully cover personal injury, medical
                expenses, death, repatriation in the event of accident or
                illness or death, cancellation or curtailment of the holiday
                by either side and loss of, damage to, or theft of the
                clients’ personal property. Activities with a greater
                inherent risk, such as mountaineering, animal tracking on
                foot, white-water rafting should be covered.
                <br />
                <br />
                Conditions in Africa are not the same as those in developed
                countries and standards of service, medical facilities,
                safety and security may often be lower than those in your
                home countries. Please note that it is the laws and
                regulations of the country in which services are provided
                which apply to your holiday arrangements and not those of
                your home country.
                <br />
                <br />
                4. Special Requests
                <br />
                <br />
                Any special requests must be advised to us at the time of
                booking. You should then confirm your requests in writing.
                Whilst every effort will be made by us to try and arrange
                your reasonable special requests, we cannot guarantee that
                they will be fulfilled. The fact that a special request has
                been noted on your confirmation invoice or any other
                documentation or that it has been passed on to the supplier
                is not confirmation that the request will be met. Failure to
                meet any special request will not be a breach of contract on
                our part unless the request has been specifically confirmed.
                We do not accept bookings that are conditional upon any
                special request being met.
                <br />
                <br />
                5. Cutting Your Holiday Short
                <br />
                <br />
                If you are forced to return home early, we cannot refund the
                cost of any services you have not used. If you cut short
                your holiday and return home early in circumstances where
                you have no reasonable cause for complaint about the
                standard of accommodation and services provided, we will not
                offer you any refund for that part of your holiday not
                completed, or be liable for any associated costs you may
                incur. Depending on the circumstances, your travel insurance
                may offer cover for curtailment and we suggest that any
                claim is made directly with them.
                <br />
                <br />
                6. Fitness to Travel and Medical Conditions
                <br />
                <br />
                We are not a specialist disabled holiday company, but we
                will take reasonable steps to cater for any special
                requirements you may have. If you or any member of your
                party has any medical problem or disability which may affect
                your stay, please provide us with full details before we
                confirm your booking so that we can try to advise you as to
                the suitability of your chosen arrangements. Acting
                reasonably, if we are unable to properly accommodate the
                needs of the person(s) concerned, we will not confirm your
                booking or if you did not give us full details at the time
                of booking, we will cancel it and impose applicable
                cancellation charges when we become aware of these details.
                <br />
                <br />
                7. Behaviour
                <br />
                <br />
                All guests staying with us are expected to conduct
                themselves in an orderly and acceptable manner and not to
                disrupt the enjoyment of other guests. If in our opinion or
                in the opinion of any person in authority, your behaviour or
                that of any member of your party is causing or is likely to
                cause distress, danger or annoyance to any of our other
                guests or any third party or damage to property, or to cause
                a delay or diversion to transportation, we reserve the right
                to terminate your booked arrangements with us immediately.
                In the event of such termination our liability to you and/or
                your party will cease and you and/or your party will be
                required to leave your accommodation or other service
                immediately. We will have no further obligations to you
                and/or your party. No refunds for lost accommodation or any
                other service will be made and we will not pay any expenses
                or costs incurred as a result of termination. You and/or
                your party may also be required to pay for loss and/or
                damage caused by your actions and we will hold you and each
                member of your party jointly and individually liable for any
                damage or losses caused by you or any member of your party.
                Full payment for any such damage or losses must be paid
                directly to the supplier prior to departure. If you fail to
                make payment, you will be responsible for meeting any claims
                (including legal costs) subsequently made against us as a
                result of your actions together with all costs we incur in
                pursuing any claim against you. We cannot be held
                responsible for the actions or behaviour of other guests or
                individuals who have no connection with your booking
                arrangements or with us.
                <br />
                <br />
                8. Excursions
                <br />
                <br />
                Excursions or other tours that you may choose to book or pay
                for whilst you are on holiday are not part of your
                contracted arrangements with us. For any excursion or other
                tour that you book, your contract will be with the operator
                of the excursion or tour and not with us. We are not
                responsible for the provision of the excursion or tour or
                for anything that happens during the course of its provision
                by the operator.
                <br />
                <br />
                9. Passport, Visa and Immigration Requirements and Health
                Formalities
                <br />
                <br />
                It is your responsibility to check and fulfil the passport,
                visa, health and immigration requirements applicable to your
                itinerary. We can only provide general information about
                this. You must check requirements for your own specific
                circumstances with the relevant Embassies and/or Consulates
                and your own doctor as applicable. Requirements do change
                and you must check the up to date position in good time
                before departure. Most countries now require passports to be
                valid for at least 6 months after your return date. If your
                passport is in its final year, you should check with the
                Embassy of the country you are visiting. Please note some
                countries require 2-3 blank pages at the end of your
                passport. You should obtain up to date advice on passport
                and visa requirements from the Embassy, High Commission or
                Consulate of your destination or country(ies) through which
                you are travelling. We do not accept any responsibility if
                you cannot travel, or incur any other loss because you have
                not complied with any passport, visa, immigration
                requirements or health formalities. You agree to reimburse
                us in relation to any fines or other losses which we incur
                as a result of your failure to comply with any passport,
                visa, immigration requirements or health formalities. Health
                facilities, hygiene and disease risks vary worldwide. You
                should take health advice about your specific needs as early
                as possible.
                <br />
                <br />
                10. Complaints
                <br />
                <br />
                We make every effort to ensure that your holiday
                arrangements run smoothly but if you do have a problem
                during your holiday, please inform the relevant supplier or
                representative immediately who will endeavour to put things
                right. If your complaint is not resolved locally, please
                contact us as soon as possible on{" "}
                <strong>
                  info@kisingasafaris.com or +256 783 700505
                </strong>
                . If the problem cannot be resolved and you wish to complain
                further, you must send formal written notice of your
                complaint to us at our office or by email to the appropriate
                e-mail address listed above (depending upon your
                destination) within 28 days of the end of your stay, giving
                your booking reference and all other relevant information.
                Please keep your letter concise and to the point. This will
                assist us to quickly identify your concerns and speed up our
                response to you. Failure to follow the procedure set out in
                this clause may affect ours and the applicable supplier’s
                ability to investigate your complaint, and will affect your
                rights under this contract. Please note that we do not offer
                an Alternative Dispute Resolution service. You can however
                access the European Commission Online Dispute (ODR)
                Resolution platform at http://ec.europa.eu/consumers/odr/.
                This ODR platform is a means of registering your complaint
                with us; it will not determine how your complaint should be
                resolved.
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
