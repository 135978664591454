import React, { Component } from "react";
import { Link } from "react-router-dom";

import Logo from "../assets/buimages/logo.png";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }
  render() {
    const collapsed = this.state.collapsed;
    const classOne = collapsed
      ? "collapse navbar-collapse"
      : "collapse navbar-collapse show";
    const classTwo = collapsed
      ? "navbar-toggler navbar-toggler-right collapsed"
      : "navbar-toggler navbar-toggler-right";
    return (
      <section className="navbar-section">
        <div className="container">
          <nav className="navbar navbar-expand-lg py-3">
            <Link className="navbar-brand" to="/">
              <img className="logo" src={Logo} width={180} />
            </Link>
            <button
              onClick={this.toggleNavbar}
              className={`${classTwo}`}
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div className={`${classOne}`} id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item ml-lg-2 mr-lg-2 d-xl-0">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item ml-lg-2 mr-lg-2 d-xl-0">
                  <Link className="nav-link" to="/safaris">
                    Safaris
                  </Link>
                </li>
                <li className="nav-item ml-lg-2 mr-lg-2 d-xl-0">
                  <Link className="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li className="nav-item ml-lg-2 mr-lg-2 d-xl-0">
                  <Link className="nav-link" to="/frequently-asked-questions">
                    FAQs
                  </Link>
                </li>
                <li className="nav-item ml-lg-2 mr-lg-2 d-xl-0">
                  <Link className="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item ml-lg-2 d-xl-0">
                  <Link className="nav-link" to="/make-your-reservation">
                    Reservations
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </section>
    );
  }
}

export default Navbar;
