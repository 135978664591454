import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ReservationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      telephone: "",
      groupsize: "",
      travelmonth: "",
      safaritype: "",
      message: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const {
      firstname,
      lastname,
      email,
      telephone,
      groupsize,
      travelmonth,
      safaritype,
      message
    } = this.state;
    let templateParams = {
      from_name: firstname + " " + lastname,
      to_name: "Kisinga Safaris",
      email: email,
      telephone: telephone,
      groupsize: groupsize,
      travelmonth: travelmonth,
      safaritype: safaritype,
      message_html: message
    };
    emailjs.send(
      "gmail",
      "template_kisinga2314",
      templateParams,
      "user_EYwNKkSNL45lK7uXfaFBz"
    );
    this.resetForm();
  }

  resetForm() {
    this.setState({
      firstname: "",
      lastname: "",
      email: "",
      telephone: "",
      groupsize: "",
      travelmonth: "",
      safaritype: "",
      message: ""
    });
  }

  notify = () => toast("Message sucessfully sent.", { delay: 1000 });

  render() {
    return (
      <form onSubmit={this.handleSubmit} name="reservation" method="post">
        <input type="hidden" name="form-name" value="reservation" />
        <div className="row">
          {/* column or input for first name */}
          <div className="col-sm-6">
            <label className="form-label font-weight-bold">First Name</label>
            <input
              type="text"
              className="form-control"
              maxLength={256}
              name="firstname"
              placeholder="First Name"
              required
              value={this.state.firstname}
              onChange={this.handleChange}
            />
          </div>
          {/* column or input for last name */}
          <div className="col-sm-6">
            <label className="form-label font-weight-bold">Last Name</label>
            <input
              type="text"
              className="form-control"
              maxLength={256}
              name="lastname"
              placeholder="Last Name"
              required
              value={this.state.lastname}
              onChange={this.handleChange}
            />
          </div>
        </div>

        {/* another row */}
        <div className="row">
          {/* column or input for email */}
          <div className="col-sm-6">
            <div className="af-class-place-holder">
              <label className="form-label font-weight-bold">Your Email</label>
              <input
                type="email"
                className="form-control"
                maxLength={256}
                name="email"
                placeholder="you@gmail.com"
                required
                value={this.state.email}
                onChange={this.handleChange}
              />
            </div>
          </div>
          {/* column or input for phone number */}
          <div className="col-sm-6">
            <div className="af-class-place-holder">
              <label className="form-label font-weight-bold">Telephone</label>
              <input
                type="tel"
                className="form-control"
                maxLength={256}
                name="telephone"
                placeholder="+44 7911 123456"
                required
                value={this.state.telephone}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        {/* another row */}
        <div className="row">
          {/* column or input for group size */}
          <div className="col-sm-6">
            <label className="form-label font-weight-bold">
              Number of People
            </label>
            <select
              name="groupsize"
              required
              className="form-control"
              value={this.state.groupsize}
              onChange={this.handleChange}
            >
              <option value>Select number of travelers</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="8 - 15">8 - 15</option>
              <option value="other">other</option>
            </select>
          </div>
          {/* column or input for travel month */}
          <div className="col-sm-6">
            <label className="form-label font-weight-bold">
              When do you hope to Travel?
            </label>
            <select
              name="travelmonth"
              required
              className="form-control"
              value={this.state.travelmonth}
              onChange={this.handleChange}
            >
              <option value>Select month</option>
              <option value="January">January</option>
              <option value="February">February</option>
              <option value="March">March</option>
              <option value="April">April</option>
              <option value="May">May</option>
              <option value="June">June</option>
              <option value="July">July</option>
              <option value="August">August</option>
              <option value="September">September</option>
              <option value="October">October</option>
              <option value="November">November</option>
              <option value="December">December</option>
            </select>
          </div>
        </div>
        <div>
          <label className="form-label font-weight-bold">Your Safari</label>
          <select
            name="safaritype"
            required
            className="form-control"
            value={this.state.safaritype}
            onChange={this.handleChange}
          >
            <option value="select">Select your Safari</option>
            <option value="3 DAYS TO THE ADRENALINE CAPITAL OF EAST AFRICA">
              3 DAYS TO THE ADRENALINE CAPITAL OF EAST AFRICA
            </option>
            <option value="3 DAYS GORILLA TRACKING - Rushaga Sector">
              3 DAYS GORILLA TRACKING - Rushaga Sector
            </option>
            <option value="3 DAYS SSESE ISLANDS - Special Safari">
              3 DAYS SSESE ISLANDS - Special Safari
            </option>
            <option value="4 DAYS GORILLA & Hearts of Uganda, Batwa Safari">
              4 DAYS GORILLA & Hearts of Uganda, Batwa Safari
            </option>
            <option value="4 DAYS: WILDLIFE & SCENARY SAFARI">
              4 DAYS FLY-IN TO THE GORILLAS SAFARI
            </option>
            <option value="six">4 DAYS: WILDLIFE & SCENARY SAFARI</option>
            <option
              value="4 DAYS WILDLIFE, CHIMPS & BIRDS SAFARI - Murchison Falls
                      NP"
            >
              4 DAYS WILDLIFE, CHIMPS & BIRDS SAFARI - Murchison Falls NP
            </option>
            <option value="5 DAYS WILDLIFE SAFARI - Queen Elizabeth NP & Bwindi INP">
              5 DAYS WILDLIFE SAFARI - Queen Elizabeth NP & Bwindi INP
            </option>
            <option
              value="6 Days: LUXURY FISHING SAFARI - Ssese Islands to Murchison
                      Falls NP"
            >
              6 Days: LUXURY FISHING SAFARI - Ssese Islands to Murchison Falls
              NP
            </option>
            <option value="6 DAYS LUXURY KIBALE, GORILLAS & LAKE MBURO SAFARI">
              6 DAYS LUXURY KIBALE, GORILLAS & LAKE MBURO SAFARI
            </option>
            <option value="8 DAYS Mountain Climbing/Hiking and Gorillas Safari">
              8 DAYS Mountain Climbing/Hiking and Gorillas Safari
            </option>
          </select>
        </div>
        {/* </div> */}
        <div>
          <label className="form-label font-weight-bold">Your Message</label>
          <textarea
            name="message"
            placeholder="Your message here"
            maxLength={5000}
            required
            className="form-control"
            rows="5"
            defaultValue={""}
            value={this.state.message}
            onChange={this.handleChange}
          />
          <button onClick={this.notify} type="submit" className="btn-yellow">
            Submit Reservation
          </button>
          <ToastContainer autoClose={6000} />
        </div>
      </form>
    );
  }
}

export default ReservationForm;
