import React, { Component } from "react";
import posed from "react-pose";

import data from "../data/topfaqs";

const Content = posed.div({
  closed: { height: 0 },
  open: { height: "auto" }
});

class FAQs extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }
  render() {
    const { open } = this.state;

    return (
      <div className="accordion-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <h3 className="section-heading" align="center">
                Frequently Asked Questions
              </h3>
              <p className="section-text py-4" align="center">
                Here are the common questions that our travellers have been
                asking answered. For more information{" "}
                <a href="/contact-us">contact one of our experts</a>.
              </p>
              {data.map(({ title, content }, i) => (
                <div>
                  <p
                    className="title font-weight-bold"
                    onClick={() =>
                      this.setState({ open: open === i ? false : i })
                    }
                  >
                    {open === i ? "🤯 " : "🙂 "}
                    {title}
                  </p>
                  <Content
                    className="content"
                    pose={open === i ? "open" : "closed"}
                  >
                    <div className="content-wrapper">{content}</div>
                  </Content>
                </div>
              ))}
            </div>
          </div>
          <div className="col-sm-2"></div>
        </div>
      </div>
    );
  }
}

export default FAQs;
