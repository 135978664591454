import React, { Component } from "react";
import { Link } from "react-router-dom";

const AllSafaris = require("../data/popularSafaris");

class TopSafaris extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AllSafaris: AllSafaris
    };
  }

  render() {
    return (
      <div>
        <div className="safaris-section py-5">
          <div className="container">
            <h3 className="section-heading" align="center">
              Top African Experiences.
            </h3>
            <p className="section-text py-4" align="center">
              We are destined and determined to give you a tailored safari
              experience
              <br />
              to some of Africa's dream destinations.
            </p>
            <div className="row">
              {AllSafaris.popularSafaris.map((safari, index) => (
                <div className="col-sm-4 py-3" key={safari.id}>
                  <div className="card">
                    <Link to={`/safaris/safari/${safari.id}`}>
                      <img
                        src={safari.image}
                        alt="images"
                        className="card-img-top"
                        alt={safari.alt}
                        style={{ height: 15 + "em" }}
                      />
                    </Link>
                  </div>
                  <p className="safari-title font-weight-bold py-2">
                    {safari.title}
                  </p>
                  <p className="safari-desc">
                    <em>
                      <strong>Safari Type:</strong> {safari.type}
                    </em>
                  </p>
                  <p className="safari-desc-breakdown">
                    <em>
                      <strong>Suitable for:</strong> {safari.size}
                    </em>
                  </p>
                  <br />
                  <a href="/safaris/`${safari.id}`" className="safari-link">
                    See more
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TopSafaris;
