import React, { Component } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import HeroImage1 from "../assets/buimages/giraffes-in-the-wild-safari-uganda.png";
import HeroImage2 from "../assets/buimages/kayaking-on-river-nile-jinja-uganda.png";
import HeroImage3 from "../assets/buimages/dining-in-the-wild-safari-uganda.png";

class Hero extends Component {
  render() {
    return (
      <div>
        <Carousel
          autoPlay
          showThumbs={false}
          showArrows={true}
          transitionTime={30}
          infiniteLoop={true}
          stopOnHover={true}
          emulateTouch={true}
          dynamicHeight={true}
        >
          <div className="w-slider-mask">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={HeroImage1}
                  alt="giraffes in the wild safari uganda"
                />
                <div className="carousel-caption align-items-center h-10">
                  <h1 className="mb-5 display-4 font-weight-bold">
                    Explore the wild
                  </h1>
                  <a href="/safaris" className="btn-yellow">See More</a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-slider-mask">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  className="d-block w-100"
                  src={HeroImage2}
                  alt="kayaking on river nile jinja uganda"
                />
                <div className="carousel-caption align-items-center h-10">
                  <h1 className="mb-5 display-4 font-weight-bold">
                    Adventure the Nile
                  </h1>
                  <a className="btn-yellow">See More</a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-slider-mask">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  class="d-block w-100"
                  src={HeroImage3}
                  alt="dining in the wild safari uganda"
                />
                <div className="carousel-caption align-items-center h-10">
                  <h1 className="mb-5 display-4 font-weight-bold">
                    Dine in the Wild
                  </h1>
                  <a href="/safaris" className="btn-yellow">See More</a>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    );
  }
}

export default Hero;
