import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import FAQs from "../components/faqs";
import CallToAction from "../components/callToAction";

const AllFAQs = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <FAQs />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default AllFAQs;
