import React from "react";

const CallToAction = () => {
  return (
    <div className="cta py-5">
      <div className="call-to-action py-5" align="center">
        <a href="/contact-us" className="btn-yellow">
          Talk to our Expert
        </a>
      </div>
    </div>
  );
};

export default CallToAction;
