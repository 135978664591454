import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import OurSafaris from "../components/safaris";
import Footer from "../components/footer";

const Safaris = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <OurSafaris />
      <Footer />
    </div>
  );
};

export default Safaris;
