export const safaris = [
  {
    id: 1,
    title: "3 DAYS TO THE ADRENALINE CAPITAL OF EAST AFRICA",
    days: 3,
    type: "Adventure / Tracking",
    size: "2 - 15 people",
    image: require("../assets/buimages/kayak-on-the-nile-jinja-uganda.png"),
    alt: "kayaking on the river nile.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5120.499087820197!2d33.19635686944204!3d0.419628265616446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177e7bb9f574a8b7%3A0xd11f39bed6ae958e!2sSource%20of%20The%20Nile.!5e0!3m2!1sen!2sug!4v1567668140883!5m2!1sen!2sug",
    description:
      "This is our flagship safari that you should all get to experience; it can even be combined with our 4-day safari to Murchison falls National park. Jinja is the heart of all activities adrenaline related be it bungee Jumping, quad biking, whitewater rafting, kayaking name it all. Jinja has it all. This particular safari combines a taste of all these activities while giving you a glimpse into this age old and very beautiful city Town. Remember! this is the place John Speke discovered the Source of the Nile.",
    itinerary: [
      {
        heading:
          "Day 1: Transfer from Entebbe to Jinja (Approximately 120kms and 2-3hrs drive)",
        body:
          "Wake up to an early breakfast and transfer from Kampala to Jinja town; the Adrenaline capital of East Africa and source of the famous Nile River. Make a stopover at the Mabira forest for a nature walk where you will come across birds, plants, trees as well as butterflies plus primates like the red tailed monkey, Grey cheeked mangabey, black & white colobus monkeys and birds like Nahan’s Francolin, Afep pigeon, African pitta, Grey parrot, African dwarf kingfisher among others. When the walk is done, head on to Jinja stopping over at for lunch at the flavors cafe and thereafter have an optional sunset cruise on the Nile at Extra Cost.",
        sleeping: " - Daniel Hotel Jinja",
        meals: " - Bed & Breakfast"
      },
      {
        heading: "Day 2: White Water Rafting/Quad Biking/Bungee Jumping",
        body:
          "On this day, you have a million activities to choose from as long as you have your energy levels high and up.  If you choose white water rafting, you will be taken to the base of ADRIFT or white water rafters for a short briefing and practical training session to help you get to know the dos and don’ts of rafting. Under the guidance of their staff, you will sail and paddle across rapids for up to 31 km starting from the Itanda Falls. The rest of the day can be spent walking through the city to visit all the old places from Dunlop to Masese, to Steel rolling mill and upto the new Jinja Market. There is also Quad Biking to try out as it takes you through the villages and then there for the ultimate adrenaline Junkie, there is Bungee Jumping. Later in the evening head back to the accommodation facility exhausted.",
        sleeping: " - Daniel Hotel Jinja",
        meals: " - Bed & Breakfast"
      },
      {
        heading: "Day 3: Transfer to Entebbe for departure",
        body:
          "After enjoying your morning breakfast, you will be driven back to Kampala for departure. On the way back, you will have a chance to stop by the Nanjjembe and Namawojjollo roadside meat joints. You will get out of your vehicle to have a taste of the road side fruits-mangoes, pineapples, bananas, you will go to the fire place to do some roasting yourself. Lessons of a life time will be learnt as you interact with the people in this area-many will be willing to share their life stories at no prompting. Continue to Kampala/ Entebbe and be dropped off at a pre-agreed destination.",
        sleeping: " - Heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 2,
    title: "3 DAYS GORILLA TRACKING - Rushaga Sector",
    days: 3,
    type: "Adventure",
    size: "2 - 15 people",
    image: require("../assets/buimages/gorillas-in-rushaga-safari-uganda.png"),
    alt: "gorilla family at bwindi impenetrable national park.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.0550315303876!2d29.712782614940185!3d-1.1207595357845732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dc23e2128118a9%3A0x7760a54a751801ad!2sRushaga%20Gorilla%20Camp!5e0!3m2!1sen!2sug!4v1567680195829!5m2!1sen!2sug",
    description:
      "This is our world famous 3-day safari to the gorilla of Rushaga starting and finishing in Kampala or Entebbe. Depending on your flights, you can have a pre-night/post-night in Entebbe or the city on Seven Hills Kampala. Records show that Bwindi Impenetrable National Park is inhabited by about 340 Mountain Gorillas making up almost half of all the mountain gorillas remaining in the world! Gorilla Trekking on this safari will take you through the steep slopes, down the valleys, into the stony thickets and finally face to face with the GIANTS of the Jungle!",
    itinerary: [
      {
        heading:
          "Day 1: Transfer from Entebbe to Southern Bwindi NP (Approximately 542kms and 9-10hrs drive)",
        body:
          "Upon arrival on an early morning flight, our expert driver guide will pick you up and transfer you straight away to the Southern Sector of Bwindi Impenetrable National Park. As we drive through the country side, you will admire the ever smiling people, the drum makers shop, the constant changing land scape and the sun in the distance. Lunch will be en-route as we continue on to the Switzerland of Uganda - the district of Kisoro part-home of the endangered mountain gorillas. Arriving early evening, we will check into our Lodge and relax by the lounge either reading a book or looking at the surrounding hills.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Gorilla tracking",
        body:
          "Wake up for it is time to prepare for the climax of your safari, the remarkable meet with the Gentle Giants!  After breakfast, transfer to the park offices-you will be briefed about the do’s and don’ts and then you will be off for the venture of your life. Trekking through the steep, densely forested hills and then finally coming across the gorillas cloaked in the foliage is a rousing moment. Do not leave these items behind- your passport, camera, hiking boots, long safari pants and shirts plus a light rain jacket. These will come in handy as you go through the thick forest and encounter the unpredictable weather of Bwindi: it can decide to rain and shine anytime.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 3: Transfer to Entebbe for departure",
        body:
          "We will depart the impenetrable forest of Bwindi and head back to Entebbe by road past the Famous Uganda Equator in time for our evening flight back home- Our journey will take us through the undulating hills such a great moment for photo opportunities.",
        sleeping: " - Heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 3,
    title: "3 DAYS SSESE ISLANDS - Special Safari",
    days: 3,
    type: "Adventure / Exploration",
    size: "2 - 15 people",
    image: require("../assets/buimages/ssese-islands.jpg"),
    alt: "ssese islands uganda.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510679.0306785395!2d32.05282200047355!3d-0.4772211132787113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19d7b01e380392bf%3A0x821b685ad7ca94bd!2sSsese%20Islands!5e0!3m2!1sen!2sug!4v1568021015312!5m2!1sen!2sug",
    description:
      "The Ssese Island special can either be done at the start or the end of your safari since it offers you best relaxation around Uganda. The entire section of Ssese Islands comprises eighty-four 84 islands around the Lake Victoria, these form a large tourist area. During this 3-day safari, you will undertake a walk in the forest, swim in the Lake Victoria, tour the town and meet the people of this place.",
    itinerary: [
      {
        heading:
          "Day 1: Arrival Entebbe to Ssese Islands ( Approximately 3 hours on the ferry )",
        body:
          "The day starts with either being picked up at the airport or picking up from Kampala city center or from your hotel in Entebbe. We will drive a short distance to the Nakiwogo Landing site and aboard the ferry that departs at 1400pm. This ferry is rather huge and has a VIP section which doesn’t differ much from the open area. It is always better to stand in the open area as you watch the ferry crash through the waves of Lake Victoria. The journey on the ferry will take us approximately 3 hours on the water and the sun will set in your back-yard while the ferry continues on. Arrival on the Island will be at 1700hrs and depending on the accommodation, we will hope off and either walk or drive there. The night will be spent exploring the beaches, dancing and a story telling moment by the water side, around a fire!",
        sleeping: " - Brovad Sands Hotel",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Beach Activities & a Forest Walk ",
        body:
          "Early rise to a sunrise beyond the waters of Lake Victoria, breakfast will find you at the Lodge veranda or in bed depending on what you desire. Awesome views of the white sand beach like those found by the coastline await you. The morning and mid-afternoon will be spent undertaking a Forest/Nature walk in Lutoboka Central Forest Reserve. The Lutoboka forest is a medium altitude moist forest lying on approximately 378 hectares of land gazetted in 1948 and if you are a birder, there are over 300 bird species in the forest and lots of primates in the Lutoboka Forest. Head back to the Lodge for a hot lunch and relax by the waters along Lake Victoria and you can enjoy some fresh fish. The evening will be spent with a storyteller around a fireplace as the stars twinkle in the skies above.",
        sleeping: " - Brovad Sands Hotel",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 3: Transfer to Entebbe, approximately 3 hours on the ferry ",
        body:
          "We will have an early breakfast and thereafter head on to the ferry at 0730, the ferry departs at 0800. Just like on the first day of your trip, journey on the ferry will take us approximately 3 hours on the water and the sun will rise in your back-yard while the ferry continues on. Arrival will be at about 11am, disembark and either drop off at Entebbe International airport or to your hotel in Eor Kampala.",
        sleeping: " - Probably Heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 4,
    title: "4 DAYS GORILLA & Hearts of Uganda, Batwa Safari",
    days: 4,
    type: "Adventure / Tracking",
    size: "2 - 15 people",
    image: require("../assets/buimages/batwa-people-and-their-culture.jpg"),
    alt: "",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d101846.3038258369!2d29.65334088744504!3d-1.0435933010523832!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19dc2704d519c4f9%3A0x412c1f73ed7fb889!2sBwindi%20Impenetrable%20National%20Park!5e0!3m2!1sen!2sug!4v1568035146941!5m2!1sen!2sug",
    description:
      "This is our famous 4-day safari to the gorilla of Rushaga and the cultures of the people in this area. Depending on your flights, you can have a pre-night/post-night in Entebbe or Kampala – traditionally referred to as the city on Seven Hills. We will take you to the Gorillas of Bwindi and also to the people, the Bakiga, the Batwa people and you will get your hands dirty. There will be opportunity to even participate in the dances, cultivate their crops and even stay around them for hours learning about their cultures. As for the Gorilla Trekking, please remember that it will take you to the steep slopes, down the valleys, into the stony thickets and finally face to face with the GIANTS of the Jungle!",
    itinerary: [
      {
        heading:
          "Day 1: Entebbe to Southern Bwindi Forest National Park ( Approximately - 542kms/9-10hrs drive )",
        body:
          "Upon arrival on an early morning flight, our expert driver guide will pick you up and transfer you straight away to the Southern Sector of Bwindi Impenetrable National Park. As we drive through the countryside, you will admire the ever smiling people, the drum makers shop, the constant changing landscape and the sun in the distance. Lunch will be en-route as we continue on to the Switzerland of Uganda, the district of Kisoro part-home of the endangered mountain gorillas. Arriving early evening, we will check into our Lodge and relax by the lounge either reading a book or looking at the surrounding hills.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2 Gorilla Tracking",
        body:
          "Wake up for it is time to prepare for the climax of your safari, the remarkable meet with the Gentle Giants!  After breakfast, transfer to the park offices-you will be briefed about the do’s and don’ts and then you will be off for the venture of your life. Trekking through the steep, densely forested hills and then finally coming across the gorillas cloaked in the foliage is a rousing moment. Do not leave these items behind- your passport, camera, hiking boots, long safari pants and shirts plus a light rain jacket. These will come in handy as you go through the thick forest and encounter the unpredictable weather of Bwindi: it can decide to rain and shine anytime.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 3: Bwindi National Park via Kabale to Batwa & Bakiga Communities ( Approximately 100kms 2-3hrs drive)",
        body:
          "We will go off the beaten track and head to what is normally not done- first, after breakfast, we will head on to Kabale city town for our next stop. Time will be spend in the communities first visiting the; Batwa-This marginalized tribe is indigenous to the Bwindi rain forest but is now homeless. “Originally, Batwa were forest-dwelling hunter-gatherers based in the Great Lakes region of Central Africa, and are widely accepted as the original inhabitants of the region but are now homeless. Bakiga- The Bakiga are very interesting people, you will spend time in their house hold. While there you will come across items like drums and harps for entertainment; spears, bows and arrows for defense and hunting; grass mats (ebirago) for sleeping on and emishambi for sitting on. Spend the day with both these people, cultivating, dancing and eating organic food straight from the Gardens of these people.",
        sleeping: " - White Horse Inn Hotel",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 4: Bwindi National Park via Kampala to Entebbe ( Approximately 435kms 6-7hrs drive)",
        body:
          "Today we will depart the Kabale a place that lies just right next to the border with Rwanda and back to Entebbe by road past the Famous Uganda Equator in time for our evening flight back home- Our journey will take us through the undulating hills such a great moment for photo opportunities.",
        sleeping: " - Probably heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 5,
    title: "4 DAYS FLY-IN TO THE GORILLAS SAFARI",
    days: 4,
    type: "Adventure / Tracking / Fly-in",
    size: "2 - 8 people",
    image: require("../assets/buimages/fly-in-safari-aeroplane.jpg"),
    alt: "fly-in safari aeroplane.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d433855.41151028045!2d29.59519887693621!3d-1.0705963669601732!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19de9d6828496617%3A0xa827b9b6bb564e9b!2sBwindi%20Impenetrable%20Forest%20National%20Park!5e0!3m2!1sen!2sug!4v1568041374257!5m2!1sen!2sug",
    description:
      "Enjoy a scenic flight over Uganda and prepare yourself for a meeting with the Mountain Gorillas in Bwindi Impenetrable Forest. With departures from Entebbe International Airport and daily departures!",
    itinerary: [
      {
        heading: "Day 1:	Arrival",
        body:
          "Land at Entebbe Airport where you will be welcomed by an expert guide and be transferred to your lodgings.",
        sleeping: " - Karibu Guest House",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Entebbe to Bwindi Impenetrable Forest National Park",
        body:
          "After an early breakfast transfer to Entebbe International Airport for a domestic flight to Kihihi, departing at 07.00. From Kihihi Airstrip (ETA 08.35 hr) you will be transferred by road in about one hour to the home of the endangered mountain gorillas.",
        sleeping: " - Engagi Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 3:	Gorilla tracking",
        body:
          "Arise early and transfer to the national park offices where you will be allocated a gorilla family in groups of 8 as well as a gorilla tracking guide. After a pre-tracking briefing by your guide touching on the gorillas generally, your assigned gorilla family as well as appropriate safety precautions, you drive to the trailhead for the start of a thrilling adventure. You spend the allowed one hour with them as they go about their daily life; feeding, moving, playing, raising their young and sometimes wrestling by the juveniles.",
        sleeping: " - Engagi Lodge",
        meals: " - Breakfast, Lunch and Dinner."
      },
      {
        heading:
          "Day 4: Bwindi Impenetrable Forest National Park to Entebbe for departure",
        body:
          "We will depart the impenetrable forest of Bwindi and head back to Entebbe with the afternoon flight. Departing Kihihi at 09h45 arriving into Entebbe at 11h25. At Entebbe International Airport connect to your international flight departing at 15h55.",
        sleeping: " - Fly home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 6,
    title: "4 DAYS: WILDLIFE & SCENARY SAFARI",
    days: 4,
    type: "Wildlife / Adventure",
    size: "2 - 15 people",
    image: require("../assets/buimages/giraffes-at-lake-mburo-national-park.png"),
    alt: "giraffes at lake mburo national park.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d255334.541618144!2d30.793441113935522!3d-0.5963455873563803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19d9a9435d3b5e13%3A0x1ffdd0cace9e206!2sLake%20Mburo%20National%20Park!5e0!3m2!1sen!2sug!4v1568039598097!5m2!1sen!2sug",
    description:
      "Welcome to our rare and very rewarding safari- this trip takes you to a former hunting area of the King of Ankole. It then proceeds to Lake Bunyonyi one of the most picturesque places in this entire world. Lake Bunyonyi does not only boast of views but is also one of the deepest lakes in Africa. It even has an island called the punishment Island which used to be a place where girls who got pregnant out of wedlock would be cast.",
    itinerary: [
      {
        heading:
          "Day 1: Transfer from Kampala to Lake Mburo National Park ( Approximately 270kms, 4-5hrs Drive )",
        body:
          "Set off early for lake Mburo National park, we will stop by the Uganda Equator to take memorable pictures while the sun is rising. Did you know that along the imaginary line of the equator, a magnetic needle has no dip and stabilizes in perfect horizontal position! Come and see for yourself! Arrive in Lake Mburo National park for a short game drive, be on the outlook for Olive baboons, giraffes, the lone leopard and the numerous Zebras.",
        sleeping: " - Eagles Nest Lodge ",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 2: Lake Mburo National Park- Lake Bunyonyi ( Approximately 240kms 4-5hrs Drive )",
        body:
          "We will wake up early for an early morning game drive as we head out of the National Park- An early lunch will be waiting for us at Igongo Cultural Center. Igongo Cultural Center chronicles the History of this Area and gives you an insight into how the King (Omugabe) of Ankole used to live. It even gives you a glimpse of the world sought after long horned Ankole cattle whose horns hung off her body like aero-plane wings. After our short visit and lunch, we will proceed to Lake Bunyonyi through the never ending beauty of Uganda. Arriving in the evening, you will relax by the lounge or the balcony of our rooms.",
        sleeping: " - Bunyonyi Overland Resort",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 3: Lake Bunyonyi",
        body:
          "After breakfast the day starts off with an early morning community walk (Hiking) through the Lake Bunyonyi area up to the top of the hills where you can see the massive lake from above. This can be combined with the boat trip; the hike is very demanding make sure you are fit enough. We take a boat on Lake Bunyonyi to one of the many Islands straddling this location- Our aim will be Byoona Amagara. A number of others exist, Bushara Island, Akampene (the famous punishment Island), Kyahugye Island, Bwama and Bucuranuka Islands too.  The batwa villages will be visited too. Relax during the heat of the day by swimming through the lake area with other options like hiking, biking and so much more.",
        sleeping: " - Bunyonyi Overland Resort",
        meals: " - Breakfast, Lunch and Dinner."
      },
      {
        heading:
          "Day 4: Lake Bunyonyi to Kampala ( Approximately 438 kms, 8-9hrs drive)",
        body:
          "On this day, after an early breakfast, you will be transferred back to Kampala with Lunch en-route at a local restaurant. You will be dropped off in Entebbe for your flight out home.",
        sleeping: " - You will be going home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 7,
    title: "4 DAYS WILDLIFE, CHIMPS & BIRDS SAFARI - Murchison Falls NP",
    days: 4,
    type: "Wildlife / Adventure",
    size: "2 - 15 people",
    image: require("../assets/buimages/murchison-falls.png"),
    alt: "murchison falls uganda",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510321.6913590477!2d31.526209577238685!3d2.195984967206947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17700563f710fc81%3A0x816c31bc104ce283!2sMurchison%20Falls%20National%20Park!5e0!3m2!1sen!2sug!4v1568042160860!5m2!1sen!2sug",
    description:
      "This is our 4-day safari which starts and finishes in Kampala. This safari is best done as a group but can also be done as a private trip. Murchison Falls National Park with the meandering Victoria Nile part of river Nile – the world’ longest river (6,650km) and the dramatic Murchison Falls is Uganda’s largest protected area, providing a stunning semi arid habitat for a variety of wildlife including elephant, buffalo, giraffe and lion. The launch trip to the bottom of the Falls is unforgettable, both to experience this mighty river and to see crocodiles, hippos and birds that bask on its banks. In the nearby Budongo Forest track chimpanzees and go bird watching.",
    itinerary: [
      {
        heading:
          "Day 1: Kampala- Murchison Falls National Park ( Approximately 393kms, 4 to 5hrs drive )",
        body:
          "Briefing on your safari. Depart Kampala or Entebbe in the morning. Visit the Ziwa Rhino sanctuary later making a stopover in Masindi for Lunch. The sanctuary offers a secure place where rhino populations can be expanded by breeding, protected from human and non-human predators and gradually re-introduced into Uganda's national parks, while at the same time, allowing the public to enjoy these majestic animals, as the project moves forward. Brief game viewing in the evening.",
        sleeping: " - Fort Murchison",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 2: Game Drives and Boat Cruise- Hike to the Top of the Falls",
        body:
          "Spend the day in the park and go on to game viewing expeditions north of the Nile. Later take a launch boat cruise from Paraa to the bottom of the mighty falls. Game viewing along the river is stunning, with some of the largest crocodiles left in Africa, hippos, elephants and birds, including the rare shoebill stork, basking on the banks of the Nile. Hike to the top of the dramatic Murchison Falls, crashing through a narrow Gorge.",
        sleeping: " - Fort Murchison",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 3: Delta Circuit and Evening Game Drive",
        body:
          "In the morning you visit the delta circuit, north of the river with its giraffe, lion and Uganda kob. As you go with the boat through this section you will have the opportunity to come across the famous shoebill stork that is the cause for many on safari. During the heat of the day relax at your lodge with a cup of iced tea or water. Evening game drive will be on the books with your guide waiting in the wings.",
        sleeping: " - Fort Murchison",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 4: Trek in Kaniyo Pabidi or Forest Walk & drive to Kampala ( Approximately 393kms, 4 to 5hrs drive )",
        body:
          "An early start. Leave for Pabidi in Budongo forest for a guided nature walk to track chimpanzees of this ever endearing forest just next to Murchison Falls National Park that you will be leaving behind.  Also, recorded in Budongo forest are about 360 bird species, 290 butterflies, 130 moths, 465 trees, and 24 mammals, of which 9 are primates. Chimpanzee tracking is now popular and presents an opportunity to see the chimps in a less crowded area of Uganda other than Kibale Forest National Park. Locating the chimps is easier between May and August as fruit is more readily available. After your walk, we will Return to Kampala arriving later in the evening in time for our outbound flight.",
        sleeping: " - Probably heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 8,
    title: "5 DAYS  WILDLIFE SAFARI - Queen Elizabeth NP & Bwindi INP",
    days: 5,
    type: "Wildlife / Adventure",
    size: "2 - 15 people",
    image: require("../assets/buimages/queen-elizabeth-national-park.jpeg"),
    alt: "queen elizabeth national park uganda.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d210301.68303948524!2d29.893565796678715!3d-0.16676754048737363!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19df489b3ec38175%3A0x3cfe5a989d13d498!2sQueen%20Elizabeth%20National%20Park!5e0!3m2!1sen!2sug!4v1568096183854!5m2!1sen!2sug",
    description:
      "Have you always desired to come and trek the Gorillas but also see the tree climbing lions of Ishasha Wilderness? Our wildlife and gorilla’s safari is your best opportunity; Queen Elizabeth brings you into contact with lions, buffalo, Elephants while Bwindi Impenetrable National Park offers you the gentle giants right next to your eyes.",
    itinerary: [
      {
        heading:
          "Day 1: Arrival Entebbe-Queen Elizabeth National park ( Approximately, 430Kms 6 to 7hrs )",
        body:
          "Upon arrival with an early morning flight we will embark on a journey down to western Uganda depending on the route we take we will have the opportunity to see the Uganda Equator twice! Did you know that along the imaginary line of the equator, a magnetic needle has no dip and stabilizes in perfect horizontal position! We will arrive later in the afternoon to our accommodation facility and check-in after a glass of juice at the reception. We will go on an evening game drive to searching for Lion, Buffalo and herds of Elephant.",
        sleeping: " - Ihamba Safari Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Queen Elizabeth NP Game Drives and Boat Cruise",
        body:
          "After an early breakfast we will go for a morning game drive through the Kasenyi plains which is a great place of coming across lions, leopards, Uganda kobs and some herds of buffalo.  Up towards the salt lakes is the Mweya peninsula which if lucky you will encounter a leopard hidden in the thickets as if ready to pounce. Lunch will be served either in the bush or hot at the lodge as we head on to the Kazinga Channel, that flows in the middle of Lake Edward and George. The boat ride here will ensure a sighting of multitudes of hippos, Crocodiles, buffaloes, water birds including the Kingfisher occasionally shooting into the water for prey.",
        sleeping: " - Ihamba Safari Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 3: Queen Elizabeth NP via Ishasha to Bwindi NP ( Approximately 200Kms, 2 to 3 hrs drive )",
        body:
          "Another day on safari as you leave Queen Elizabeth National park behind and head on to Bwindi Impenetrable National Park. You will have time to go through Ishasha home of the tree climbing lions and if it is a good day, you will see dozens perched high up in the trees of this sector. Continue to Bwindi Impenetrable as both the vegetation and landscape changes in front of your eyes. Depending on the time you arrive, you will have time enough to either take a walk through the village of Buhoma or relax by the balcony of your room.",
        sleeping: " - Buhoma Haven Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading: "Day 4: Gorilla tracking",
        body:
          "Wake up for it is time to prepare for the climax of your safari, the remarkable meet with the Gentle Giants!  After breakfast, transfer to the park offices will be organized - you will be briefed about the do’s and don’ts and then you will be off for the venture of your life. Trekking through the steep, densely forested hills and then finally coming across the gorillas cloaked in the foliage is a rousing moment.",
        sleeping: " - Buhoma Haven Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 5: Bwindi Forest NP to Entebbe for departure ( Approximately 530Kms, 9 to 10hrs drive )",
        body:
          "We will depart the impenetrable forest of Bwindi and head back to Entebbe or Kampala by road with lunch on the way past the Famous Uganda Equator in time for our evening flight back home- Our journey will take us through the undulating hills such a great moment for photo opportunities.",
        sleeping: " - Heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 9,
    title:
      "6 Days: LUXURY FISHING SAFARI - Ssese Islands to Murchison Falls NP",
    days: 6,
    type: "Wildlife / Fishing",
    size: "2 - 15 people",
    image: require("../assets/buimages/ssese-islands.png"),
    alt: "ssese islands on lake victoria uganda",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d510679.0306785395!2d32.05282200047355!3d-0.4772211132787113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19d7b01e380392bf%3A0x821b685ad7ca94bd!2sSsese%20Islands!5e0!3m2!1sen!2sug!4v1568021015312!5m2!1sen!2sug",
    description:
      "With this trip we will combine fishing through the waters of Lake Victoria (Ssese Islands) and the mighty River Nile (Murchison Falls National Park). Fishing in Lake Victoria offers an ultimate chance to get your hands onto the world’s largest freshwater fish the-Nile Perch. Over time, we have come to know the best spots, methods and have excellent skippers at hand to guide you. During the safari we will ensure you get all types of anglers be it novice or experienced, we are the people to turn to. About 350 species of fish are so far recognized to exist in the water bodies of Uganda-Tilapia, large Nile Perch, “Vundu” catfish, “Semutundu” Catfish Rastrineobola, lung fish and Protopterus plus many more.",
    itinerary: [
      {
        heading:
          "Day 1: Arrival Entebbe-Ssese Islands ( Approximately 3 hours ) on the ferry",
        body:
          "The day starts with either being picked up at the airport or picking up from Kampala city center. We will drive a short distance to the Nakiwogo Landing site and aboard the ferry that departs at 1400pm. We suggest you bring as much sun protection as possible – hats, sunglasses, creams as it can be quite hot out on the lake-A camera, a pair of binoculars, sandals, T-Shirts, a rain jacket just in case of weather change. As the ferry strides on, it is always better to stand in the open area as you watch the ferry crash through the waves of Lake Victoria. The journey on the ferry will take us approximately 3 hours on the water and the sun will set in your backyard while the ferry continues on. Arrival on the Island will be at 1700hrs and depending on the accommodation, we will hope off and either walk or drive there. The night will be spent exploring the beaches, dancing and a story telling moment by the water side, around a fire!",
        sleeping: " - Brovad Sands Hotel",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Fishing Expedition on the Islands",
        body:
          "Early rise to a sunrise beyond the waters of Lake Victoria, breakfast will find you at the Lodge veranda or in bed depending on what you desire. Awesome views of the white sand beach like those found by the coastline await you. The day will be spent on a fishing expedition, other than the Nile perch, additional local fish species caught here include tilapia and lungfish. All equipment and lures for trolling for perch for up to 4 lines per boat are supplied, but anglers are free to bring their own.  Anything damaged or lost will need to be paid for before disembarkation. ",
        sleeping: " - Brovad Sands Hotel",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 3: Ssese Islands to Murchison Falls National Park ( Approximately 3 hours on the ferry/ 393kms 4 to 5hrs drive )",
        body:
          "We will have an early breakfast and thereafter head on to the ferry at 0730, the ferry departs at 0800. Just like on the first day of your trip, journey on the ferry will take us approximately 3 hours on the water and the sun will rise in your back yard while the ferry continues on. Arrival will be at about 11am, and head on to Murchison falls National park for our next fishing expedition. The journey will take us down towards Uganda’s largest National Park.",
        sleeping: " - Bakers Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading: "Day 4: Fishing Expedition on Murchison Falls",
        body:
          "We start the day by transfer to the bottom of the falls with a boat and here, our aim will be the Nile perch in this area, amidst the wallowing waters. Other species (Awaka fish and Ungara) easily caught using the fly method will be in plenty. The boats will be equipped with a sun canopy, rain suits, life jackets, basic first aid box and a fire extinguisher for safety. This boat will provide you with a rare opportunity of viewing game along the river which is so stunning, with some of the largest crocodiles left in Africa, hippos, elephants and birds, including the rare shoebill stork, basking on the banks of the Nile.",
        sleeping: " - Bakers Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 5: Fishing Expedition Continues",
        body:
          "Yet another day fishing, did we miss anything the previous day? We will catch fish in the big pools below the falls with lures, live bait and flies for the large Nile perch. Just like yesterday, there will be plenty of other species to catch for Sport. Lunch will be by the waters of Lake Victoria including a taste of the famous Nile special for our daring guests. It’s only in Uganda that you will have an opportunity to enjoy the outstanding sports fishing within the stunning unpolluted waters surrounded by very pristine natural environments ass you relax and with no distraction but your fishing activity.",
        sleeping: " - Bakers Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 6: Murchison Falls NP drive to Kampala ( Approximately 393kms 4 to 5hrs drive )",
        body:
          "We will have breakfast in the National park and later on embark on our journey back to Kampala or Entebbe. We will return to Kampala arriving later in the evening in time for our outbound flight.",
        sleeping: " - Flying home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 10,
    title: "6 DAYS LUXURY KIBALE, GORILLAS & LAKE MBURO SAFARI",
    days: 6,
    type: "Wildlife / Tracking / Adventure",
    size: "2 - 15 people",
    image: require("../assets/buimages/hippo-at-lake-mburo-national-park.png"),
    alt: "hippo at lake mburo national park.",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d85109.08122454672!2d30.889330488138725!3d-0.6096257851245982!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x19d9a9a32ea89b03%3A0xbb43fc0fb7105dbe!2sLake%20Mburo%20National%20Park%20Gate!5e0!3m2!1sen!2sug!4v1568097359204!5m2!1sen!2sug",
    description:
      "Welcome to this people favourite  trip to the chimps of Kibale, the gorillas of Bwindi and rewarding Lake Mburo National Park.",
    itinerary: [
      {
        heading:
          "Day 1: Entebbe to Kibale Forest National Park ( Approximately 390 Kms )",
        body:
          "Arrive at EBB  and drive  south towards the town of  Fort Portal – Uganda's tourism city, We will go past rural farm lands and homesteads, children will line the road side as you pass-by, please remember to wave back. Fort portal rests in the shadow of the fabled ‘Mountains of the Moon’ and is famous for her many tea plantations. You will continue into Kibale National Park arriving at Primate Lodge Kibale for late lunch.   Spend the afternoon relaxing at the Lodge.",
        sleeping: " - Primate Lodge Kibale",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 2: Kibale Forest National Park and Afternoon at Leisure",
        body:
          "The forest opens its doors for a sensational chimpanzee tracking experience. Following the pre-tracking briefing, you will walk or drive to the trailhead for the start of an unforgettable adventure – hiking in the footsteps of the habituated family of chimpanzees. En-route, your guide will enthral you with stories and facts about the forest and her other inhabitants including other species of primates and birds and other big mammals. In the afternoon enjoy a guided nature walk through the Bigodi wetland.   Bigodi Swamp Walk is an initiative of the local community and is known for its rich diversity of birds and primates. The path and board walk maybe flooded and muddy after heavy rainfall and gumboots are recommended. The walk is about 4km in length taking 3-4 hours at birding pace with local community guides. All proceeds from eco- tourism go back to the community, a conservation project well worth supporting. Dinner and overnight.",
        sleeping: " - Primate Lodge Kibale",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 3: Kibale Forest National Park to Bwindi Impenetrable National Park( Approximately 385kms or 6 to 7hrs )",
        body:
          "Early morning drive from Kibale Forest National Park with scenic views of crater lakes and the surrounding rolling hills, you will go through banana farmlands, Afterwards, you will proceed south alongside the majestic Rwenzori ‘Mountains of the Moon’ through Ishasha sector. If you arrive in time, take a village walk to encounter the community.",
        sleeping: " - Engagi Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading: "Day 4: Gorilla Tracking ( 3 to 8 hrs )",
        body:
          "Arise early today and prepare for an amazing adventure; an unforgettable encounter with the gentle giants of Bwindi! Following breakfast, transfer to the park offices where participants are divided into groups of 8 and allocated a gorilla family as well as a tracking guide. After a pre-tracking briefing by your guide (touches on the gorillas generally, your assigned gorilla family as well as appropriate safety precautions), you drive to the trailhead for the start of your thrilling adventure – participants can expect to walk long distances in steep and sometimes muddy conditions with rain overhead, which can be tough and require a degree of fitness. Granted, the time taken can be quite unpredictable taking anything from 2 to 8hrs or longer however the thrill of coming face to face with a giant Mountain Gorilla silverback will more than compensate for the effort in their quest. Upon sighting the gorillas, you spend the allowed one hour with them as they go about their daily life; feeding, moving, playing, raising their young and sometimes wrestling by the juveniles – it is a totally unique and unforgettable experience! Afterwards, you hike back to base where your Driver will be waiting to transfer you to the lodge for a deserved shower and lunch or dinner depending on the time taken on the trek.",
        sleeping: " - Engagi Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 5: Bwindi Impenetrable to Lake Mburo National Park ( Approximately 250km or 5 to 6hrs )",
        body:
          "After breakfast we depart the Bwindi Impenetrable National Park and head to Lake Mburo National Park. Enjoy lunch at a restaurant before we enter the national park, and with a game drive en-route we will calmly proceed to our accommodation, with beautiful views of the park. In the afternoon go for a scheduled boat drive to give you enough time to view other wildlife and rare bird species.",
        sleeping: " - Mihingo Lodge",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 6: Lake Mburo National Park to Entebbe ( Approximately 250km or 5 to 6hrs )",
        body:
          "Early start of the day we will go for a bush walk guided by a ranger. Experience a safari on foot that will allow you to notice the smaller, often overlooked, details and usually brings you much closer to the wildlife than when in a car.  Later on, depart the park and head on to Entebbe International airport for our flight home and connect in time for your flight out.",
        sleeping: " - Flying Home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  },
  {
    id: 11,
    title: "8 DAYS Mountain Climbing/Hiking and Gorillas Safari",
    days: 8,
    type: "Hiking / Tracking",
    size: "2 - 15 people",
    image: require("../assets/buimages/climbing-the-rwenzoris.png"),
    alt: "climbing the rwenzori mountain in uganda",
    mapUrl:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d439979.4888480574!2d29.67946716989709!3d0.3722011988179394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x17617d0ff71884d7%3A0xb92785ca8b99bc36!2sRwenzori%20Mountains!5e0!3m2!1sen!2sug!4v1568098690269!5m2!1sen!2sug",
    description:
      "In this safari we combine that mountains of Uganda with the Gentle Giants of Bwindi Impenetrable National park. This safari requires some level of fitness as you will both Hike to some really lucrative peaks and even trek the Mountain Gorillas. Brace yourself for the ultimate Experience never tried before, you are the pioneer once you book this trip.",
    itinerary: [
      {
        heading: "Day 1:	Arrival Entebbe",
        body:
          "Arrival at the airport and you will be transferred to your hotel for the very first night in this country- As you depart the airport, make sure your check list is out because you might even be able to tick off a number even before going on safari: the weaverbirds, swallows, common bulbul etc. Check into your Guest house and relax.",
        sleeping: " - Airport Guest House",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading:
          "Day 2: Drive to the Rwenzori Mountain Ranges ( Approximately 380kms or 5 to 6 hrs Drive )",
        body:
          "You will drive from Kampala towards the Rwenzori mountains- these mountains are sometimes referred to as the Mountains of the Moon, a name that comes from the highest peaks being snow-capped throughout the year standing at 5,109 metres. We will have lunch en-route arriving late afternoon for a drink at your room veranda as you watch the ranges just above.",
        sleeping: " - Hotel Magherita",
        meals: " - Breakfast, Lunch and Dinner"
      },
      {
        heading: "Day 3: Mountain Hiking in Rwenzori",
        body:
          "Enjoy an early breakfast and then head for one-day Hiking excursion into the mountain ranges. The hike is strenuous and calls for a particular level of fitness- depending on the weather you may see some of the peaks (Mountain Stanley 5109 metres, Mountain Speke 4890 and Mountain Baker 4843). You will also go through local communities along you paths, please wave back when they do, especially the children.",
        sleeping: " - Hotel Magherita",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 4: Drive to Mountain Mgahinga ( Approximately 315kms, 4 to 5hrs drive )",
        body:
          "We will say goodbye to the foothills of the Rwenzori Ranges and head on to Kisoro district high up we will head to the top of the mountains. The mountain ranges of this area are part of the bigger volcanoes Virunga ranges consisting of eight major volcanoes. Most of them are dormant, except Mount Nyiragongo 3,462 metres and Mount Nyamuragira 3,063 metres, both in the DRC. Recent eruptions occurred in 2006 and in January 2010. Mount Karisimbi is the highest volcano at 4,507 metres (14,787 ft). The oldest mountain is Mount Sabyinyo, which rises 3,634 metres (11,923 ft) above sea level. Upon arrival, we will spend the evening by the fireplace and later on relax as we wait for our hiking the following day. ",
        sleeping: " - Mountain Mgahinga Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading: "Day 5: Mountain Mgahinga Hiking",
        body:
          "We will have three options to choose from, Mt Sabino, Gahinga and Muhavura pieces which can all be seen from Mountain Mgahinga National park. Mt. Sabinyo, at 3,669m, takes approximately eight hours to cover the 14kms round trip, following a steep ridge up to the peak- this one is very exciting and the most challenging of the three, you have to be fit. On the other hand, Mt. Gahinga (3,474m), takes about six hours to climb and return. You will find a swamp-filled crater and giant lobelia waiting for you at the top. Lucky climbers may spot golden monkeys on their way through the bamboo forest. Finally, Mt. Muhavura is the highest peak of the three at 4,127m, and this 12km round trip takes around eight hours. Once at the top, hikers are rewarded on a clear day with views of the Virunga Volcanoes, Lake Edward, Bwindi Impenetrable Forest and the peaks of the Rwenzoris. After hiking one of these, return to the Lodge for a warm shower and relax your muscles after so long a walk in the mountains.",
        sleeping: " - Mountain Mgahinga Lodge",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 6: Mountain Mgahinga to Southern Bwindi Forest NP ( Approximately 110kms, 1 to 2hrs drive )",
        body:
          "Upon arrival on an early morning flight, our expert driver guide will pick you up and transfer you straight away to the Southern Sector of Bwindi Impenetrable National Park. As we drive through the country side, you will admire the ever smiling people, the drum makers shop, the constant changing land scape and the sun in the distance. Lunch will be en-route as we continue on to the Switzerland of Uganda, the district of Kisoro part-home of the endangered mountain gorillas. Arriving early evening, we will check into our Lodge and relax by the lounge either reading a book or looking at the surrounding hills.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch."
      },
      {
        heading: "Day 7: Gorilla tracking",
        body:
          "Wake up for it is time to prepare for the climax of your safari, the remarkable meet with the Gentle Giants!  After breakfast, transfer to the park offices-you will be briefed about the do’s and don’ts and then you will be off for the venture of your life. Trekking through the steep, densely forested hills and then finally coming across the gorillas cloaked in the foliage is a rousing moment.",
        sleeping: " - Lake Mutanda Resort",
        meals: " - Breakfast, Lunch."
      },
      {
        heading:
          "Day 8:	Bwindi Forest National Park to Entebbe for departure ( Approximately 542kms 9 to 10hrs drive )",
        body:
          "We will depart the impenetrable forest of Bwindi and head back to Entebbe by road past the Famous Uganda Equator in time for our evening flight back home- Our journey will take us through the undulating hills such a great moment for photo opportunities.",
        sleeping: " - You will be heading home.",
        meals: " - Breakfast, Lunch."
      }
    ]
  }
];
