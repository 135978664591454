import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

import ExpertGuide from "../assets/buimages/bwindi-nature-walk-safari-in-uganda.png";
import EscortHiking from "../assets/buimages/mt-elgon-hiking-safari-in-uganda.jpg";
import Edwin from "../assets/buimages/kugonza.jpg";
import Eldrine from "../assets/buimages/eldrine.png";
import CEO from "../assets/buimages/ceo.png";

const About = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <div className="hero-image-section">
        <div className="hero-image-about-us">
          <div className="hero-text">
            <h1 className="hero-headng font-weight-bold">About Us</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-sm-8">
            <h3>Who we are</h3>
            <p>
              We are a Ugandan Safari company specializing mainly in organizing{" "}
              <strong>
                Adventure, Cultural, Religious, hill station & wildlife tours
              </strong>{" "}
              in <strong>Uganda and the East African region</strong> through a
              sprawling network. We offer services that include travel planning,
              itinerary design, hotel bookings, ticket reservations and
              transport facilities. We are experts in providing holiday
              packages, customized as per client’s need and budget.
              <br />
              <br />
              We serve our customers with dedication to deliver satisfaction
              through quality service from our experienced staffs. We have been
              receiving positive <strong>feedback</strong> from our existing
              clients and hence one can expect a cordial reception.
            </p>
            <br />
            <br />
            <h3>Quality of Service</h3>
            <p>
              We measure ourselves by quality and time to time services. At any
              hour of the day and from anywhere in the world our staffs are
              reachable ready to give ultimate satisfaction and luxury depending
              upon the traveler's need. <br />
              <br />
            </p>
            <img
              className="slot-image"
              src={ExpertGuide}
              width={80 + "%"}
              alt="bwindi nature walk safari in uganda"
            />
            <figcaption className="image-caption">
              <em>Nature walk at Bwindi Impenetrable National Park.</em>
            </figcaption>
            <p className="af-class-text-14 af-class-desc">
              <br />
              Whether you are on a{" "}
              <strong>
                <em>
                  birder, a trekking tourist or tranquil beach holiday maker
                </em>
              </strong>
              , we provide you a niche and satisfied tour along with
              professional guides. We always ready to offer customers a wide
              range of services because we specialize in providing tailor-made
              holidays and other travel needs to its valuable.
            </p>
            <br />
            <br />
            <h3>Why choose us</h3>
            <p>
              Our un-rivaled strong associations with budget and luxury hotels
              across the country enables us to give our clients their best value
              for money. This makes us the most attractive Safari agency for
              you. Our team is prompt in their response to queries and
              inquiries. This is what has earned us reputation as one of the
              best and most efficient tour and travel operators in East Africa
              by the clients and overseas partners. <br />
              <br />
            </p>
            <img
              className="slot-image"
              src={EscortHiking}
              width={80 + "%"}
              alt="mount elgon hiking on a safari in uganda."
            />
            <figcaption className="image-caption">
              <em>Our escort with a tourist on a Mount Elgon hike.</em>
            </figcaption>
            <p>
              <br />
              Your choice to work with us will enable you enjoy;
              <br />
              <ul>
                <li>Personalized assistance on arrival & departure.</li>
                <li>All sorts of transfer from car to coach in any city.</li>
                <li>
                  Accommodation in all categories of hotels from budget to 5
                  Star hotels.
                </li>
                <li>Multilingual guide services.</li>
                <li>Escort services.</li>
                <li>Air, train and bus tickets.</li>
                <li>
                  Sightseeing in comfortable chauffeur driver cars to coaches.
                </li>
                <li>Special cultural theme events if any</li>
              </ul>
              With us be assured of a comfortable and hassle free holiday .Right
              from the arrival at the airport to personalized assistance of
              departure, we take care of all the needs of the travellers. Our
              guests just sit back and enjoy their holidays with all the value
              for the money they have spent.
            </p>
            <br />
          </div>
          <div className="col-sm-4">
            <h3>Our Team</h3>
            <img
              className="slot-image"
              src={Edwin}
              width={80 + "%"}
              alt="our ceo"
            />
            <figcaption className="image-caption">
              <em>
                Mr Edwin Kugonza, <br />
                Business Development
              </em>
            </figcaption>
            <br />
            <br />
            <img
              className="slot-image"
              src={CEO}
              width={80 + "%"}
              alt="our ceo"
            />
            <figcaption className="image-caption">
              <em>
                Ms Eldrine Kemigisha, <br />
                CE0
              </em>
            </figcaption>
            <br />
            <br />
            <img
              className="slot-image"
              src={Eldrine}
              width={80 + "%"}
              alt="our ceo"
            />
            <figcaption className="image-caption">
              <em>
                Ms Jesca Alinaitwe, <br />
                Hospitality Executive
              </em>
            </figcaption>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
