import React, { Component } from "react";
import posed from "react-pose";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Amenities from "../components/amenities";
import CallToAction from "../components/callToAction";

const AllSafaris = require("../data/safaris.js");

const ItineraryContent = posed.div({
  closed: { height: 0 },
  open: { height: "auto" }
});

class Safari extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  render() {
    const { id } = this.props.match.params;
    const { open } = this.state;
    const displaySafari = () => {
      return AllSafaris.safaris.map(safari => {
        if (safari.id == id) {
          const bgImage = safari.image;
          const styles = {
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${bgImage})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 60%"
          };
          return (
            <div key={safari.id}>
              <div className="hero-image-section">
                <div className="hero-image" style={styles}>
                  <div className="hero-text">
                    <h2 className="hero-headng font-weight-bold py-4">
                      {safari.title}
                    </h2>
                    <a href="/make-your-reservation" className="btn-yellow">
                      Make your resevation
                    </a>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row py-5">
                  <div className="col-sm-2"></div>
                  <div className="col-sm-8">
                    <div className="safari-overview">
                      <h3 className="section-heading">Safari Overview</h3>
                      <p className="safari-text">
                        <strong>Safari Type:</strong> <em>{safari.type}.</em>
                        <br />
                        <strong>Group size:</strong> Suitable for{" "}
                        <em>{safari.size}.</em>
                        <br />
                        <strong>Duration:</strong> <em>{safari.days} days.</em>
                        <br />
                        <br />
                        {safari.description}
                      </p>
                    </div>
                    <div className="accordion py-5">
                      {safari.itinerary.map((day, i) => (
                        <div>
                          <p
                            className="title font-weight-bold"
                            onClick={() =>
                              this.setState({ open: open === i ? false : i })
                            }
                          >
                            {open === i ? "🤯 " : "🙂 "}
                            {day.heading}
                          </p>
                          <ItineraryContent
                            className="content"
                            pose={open === i ? "open" : "closed"}
                          >
                            <div className="content-wrapper">
                              <p>
                                {day.body}
                                <br />
                                <br />
                                <strong>Overnight: </strong>
                                <em>{day.sleeping}</em>
                                <br />
                                <strong>Meals: </strong>
                                <em>{day.meals}</em>
                              </p>
                            </div>
                          </ItineraryContent>
                        </div>
                      ))}
                    </div>
                    <div className="safari-map">
                      <iframe
                        src={safari.mapUrl}
                        width={100 + "%"}
                        height={380}
                        frameBorder={0}
                        style={{ border: 0 }}
                        allowFullScreen
                      />
                    </div>
                    <Amenities />
                  </div>
                  <div className="col-sm-2"></div>
                </div>
              </div>
            </div>
          );
        }
      });
    };

    return (
      <div>
        <TopNav />
        <Navbar />
        {displaySafari()}
        <CallToAction />
        <Footer />
      </div>
    );
  }
}

export default Safari;
