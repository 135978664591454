import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";

const Privacy = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <div>
        <div className="container py-5">
          <h3 className="privacy-heading">Privacy Policy</h3>
          <br />
          <div>
            <strong>
              We respect the privacy of our customers and therefore we are
              committed to the following privacy policy, which is designed to
              give you peace of mind and confidence. What personal information
              do we ask for?
            </strong>
            <br />
            <br />
            The only personal information about you we gather is information
            provided by you upon request. We ask you for personal information in
            a variety of circumstances:
            <br />
            <br />
            Brochure requests and registering for our e-newsletter. We need your
            name, address and/or e-mail address so that we can send out your
            brochure order, and/or advise you of special offers/promotions we
            think you would be interested in. On all forms you will be given the
            opportunity to opt-in to receiving occasional marketing information
            from Kisinga Safaris.
            <br />
            <br />
            Booking Form Making an enquiry through the booking form requires
            name, email address and country of origin so that we can assess the
            best way to be in touch. Other fields allow us to narrow down the
            options before we speak to you. Your information is not shared with
            third parties and is only added to our newsletter database for
            marketing information if such a service is opted into.
            <br />
            <br />
            Does Kisinga Safaris site use cookies? Yes. Kisinga Safaris uses
            ‘cookies’ to collect information about your use of the website and
            to assist repeat visitors, for example those ordering marketing
            literature from us. A cookie is a small information file sent by a
            web server to store on your web browser (Internet Explorer or
            Firefox for example), so it can later be read back from that
            browser. This is useful for having the browser remember some
            specific information. This recognizes the computer and not you
            personally.
            <br />
            <br />
            Most browsers automatically accept cookies but you can delete
            existing cookies from your browser. You can also edit your browser
            options to choose not to receive cookies in future. We may use
            cookies to keep track of the transaction from one page to the next.
            Cookies enable us to monitor site usage levels allowing us to
            improve your website experience. Cookies used cannot be used to
            trace visitor behaviour across other websites.
            <br />
            <br />
            We may also use your IP (Internet Protocol) address to diagnose
            problems with our server and administer the site – monitoring
            visitor traffic patterns and site usage to help us develop and
            improve the Kisinga Safaris site further. Please note we do not
            store any individual data from our cookies, we simply use the
            information to understand user trends.
            <br />
            <br />
            How does Kisinga Safaris protect customer information? A secure
            server protects all the information you give us to make a payment on
            our site. The secure server software SSL (Secure Socket Layers)
            encrypts all information held. The preferred partner for payment
            gateway is Paypoint. You will be providing your name, address and
            card information to Paypoint when making a payment on the Kisinga
            Safaris site. Paypoint is fully compliant with the Payment Card
            Industry Data Security Standard. We direct you to their Privacy
            Policy for further assurance in this area. Kisinga Safaris are in no
            way responsible for the Privacy Policy and business activities of
            Paypoint.
            <br />
            <br />
            Will Kisinga Safaris disclose any of this personal information to
            third parties? Kisinga Safaris will only disclose your name and
            booking details to our suppliers for operational purposes. We will
            not pass on your details to other companies for marketing purposes.
            <br />
            <br />
            Data Protection Updating and accessing your information If you wish
            to update or amend the information which Kisinga Safaris may hold
            about you or wish Kisinga Safaris to cease using your data please
            contact us at
            <strong> info@kisingasafaris.com</strong>.
            <br />
            <br />
            How will I know if any changes have been made to this policy? Any
            changes made to our privacy policy will be made to this page. Your
            continued use of the website signifies your agreement to any
            changes.
            <br />
            <br />
            What about the other sites you link to? Kisinga Safaris, including
            its owners, employees and contractors, is not responsible for the
            privacy policies or content of sites that we link to. If you have
            any further questions relating to this policy, or if at any time you
            feel Kisinga Safaris has not adhere to this policy please let us
            know by emailing us at <strong>info@kisingasafaris.com</strong>.
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
