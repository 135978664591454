import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <div className="footer-section py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm py-3">
              <p className="footer-title font-weight-bold py-2">Menu</p>
              <ul className="list-unstyled">
                <li>
                  <a className="footer-link" href="/">
                    Home
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/safaris">
                    Safaris
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/about-us">
                    About Us
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/frequently-asked-questions">
                    FAQs
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/contact-us">
                    Contact Us
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/make-your-reservation">
                    Reservations
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm py-3">
              <p className="footer-title font-weight-bold py-2">More</p>
              <ul className="list-unstyled">
                <li>
                  <a className="footer-link" href="/frequently-asked-questions">
                    Help
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/terms">
                    Terms and Conditions
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm py-3">
              <p className="footer-title font-weight-bold py-2">Social Media</p>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="footer-link"
                    href="https://web.facebook.com/kisingasafarisug/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://twitter.com/kisingasafaris1"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Twitter
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-sm py-3">
              <p className="footer-title font-weight-bold py-2">Contact Us</p>
              <p className="footer-text">
                Plot 89, Mawanda Road
                <br />
                P.O Box 23434 Kampala - Uganda
                <br />
                Tel: +256 393 283211
                <br />
                Mob: +256 783 700505
                <br />
                Email: info@kisingasafaris.com
              </p>
            </div>
          </div>
          <div className="copyright-text">
            <small>© Kisinga Safaris Uganda 2019. All Rights Reserved</small>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
