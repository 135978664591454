import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./containers/home";
import Safaris from "./containers/safaris";
import Safari from "./containers/safari";
import AboutUs from "./containers/about";
import ContactUs from "./containers/contact";
import Reservation from "./containers/reservations";
import FAQs from "./containers/faqs";
import Terms from "./containers/terms";
import Privacy from "./containers/privacy";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Route exact path="/" component={Home} />
        <Route exact path="/safaris" component={Safaris} />
        <Route exact path="/safaris/safari/:id" component={Safari} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/make-your-reservation" component={Reservation} />
        <Route exact path="/frequently-asked-questions" component={FAQs} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/privacy-policy" component={Privacy} />
      </BrowserRouter>
    </div>
  );
}

export default App;
