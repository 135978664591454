import React, { Component } from "react";
import * as emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      telephone: "",
      message: ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { name, email, telephone, message } = this.state;
    let templateParams = {
      from_name: name,
      to_name: "Kisinga Safaris",
      email: email,
      telephone: telephone,
      message_html: message
    };
    emailjs.send(
      "gmail",
      "template_zB3aYyil",
      templateParams,
      "user_EYwNKkSNL45lK7uXfaFBz"
    );
    this.resetForm();
  }

  resetForm() {
    this.setState({
      name: "",
      email: "",
      telephone: "",
      message: ""
    });
  }

  notify = () => toast("Message sucessfully sent.", { delay: 1000 });

  render() {
    return (
      <div className="contact-form py-3">
        <form onSubmit={this.handleSubmit} name="contact" method="post">
          <label className="form-label font-weight-bold">Your Name</label>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            className="form-control"
            value={this.state.name}
            onChange={this.handleChange}
            required
          />
          <label className="form-label font-weight-bold">Your Email</label>
          <input
            type="email"
            name="email"
            placeholder="Your email"
            className="form-control"
            value={this.state.email}
            onChange={this.handleChange}
            required
          />
          <label className="form-label font-weight-bold">Telephone</label>
          <input
            type="tel"
            name="telephone"
            placeholder="+44 800800800"
            className="form-control"
            value={this.state.telephone}
            onChange={this.handleChange}
            required
          />
          <label className="form-label font-weight-bold">Your Message</label>
          <textarea
            name="message"
            className="form-control"
            rows="5"
            value={this.state.message}
            onChange={this.handleChange}
          ></textarea>
          <button onClick={this.notify} type="submit" className="btn-yellow">
            Send Message
          </button>
          <ToastContainer autoClose={6000} />
        </form>
      </div>
    );
  }
}

export default ContactForm;
