import React, { Component } from "react";

import BwindiVideo from "../assets/buvideos/bwindi-impenetrable-national-park-uganda.mp4";

class HighlightVideos extends Component {
  render() {
    return (
      <div>
        <div className="video-section py-5">
          <div className="container">
            <div className="row">
              <div className="col-sm-2"></div>
              <div className="col-sm-8" align="center">
                <h3 className="section-heading">Watch our Experiences</h3>
                <p className="section-text py-4">
                  Our experiences are designed to give you a memorable stay
                  <br />
                  on the african continent.
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                  <video
                    loop
                    controls
                    autoplay
                    className="embed-responsive-item"
                  >
                    <source src={BwindiVideo} type="video/mp4" />
                    Your browser does not support the video tag
                  </video>
                </div>
              </div>
              <div className="col-sm-2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default HighlightVideos;
