import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import ReservationForm from "../components/reservationForm";

const Reservations = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <div className="reservation-form-section py-5">
        <div className="container">
          <h3 className="section-heading" align="center">
            Lets tailor your Experience.
          </h3>
          <p className="section-text py-4" align="center">
            Need a tailor made Safari for one or a group of people!?
            <br />
            Our team is here to help you.
          </p>
          <div className="row">
            <div className="col-sm-2"></div>
            <div className="col-sm-8">
              <ReservationForm />
            </div>
            <div className="col-sm-2"></div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Reservations;
