import React from "react";

import TopNav from "../components/topnav";
import Navbar from "../components/navbar";
import Hero from "../components/hero";
import TopSafaris from "../components/topsafaris";
import HighlightVideo from "../components/video";
import FAQs from "../components/faqs";
import Footer from "../components/footer";

const Home = () => {
  return (
    <div>
      <TopNav />
      <Navbar />
      <Hero />
      <TopSafaris />
      <HighlightVideo />
      <FAQs />
      <Footer />
    </div>
  );
};

export default Home;
